import moment from "moment";
import { isDateInDST_US } from "@/core/plugins/TimerService";

export const convertTradeTime = (from, to) => {
  const isDST = isDateInDST_US();

  const createdFrom = from
    ? moment(from)
        .subtract(1, "day")
        .format(`YYYY-MM-DD[T]${isDST ? 21 : 22}:00:00.000[Z]`)
    : moment
        .utc()
        .subtract(1, "day")
        .format(`YYYY-MM-DD[T]${isDST ? 21 : 22}:00:00.000[Z]`);

  const createdTo = to
    ? moment(to).format(`YYYY-MM-DD[T]${isDST ? 20 : 21}:59:59.000[Z]`)
    : moment.utc().format(`YYYY-MM-DD[T]${isDST ? 20 : 21}:59:59.000[Z]`);

  return [createdFrom, createdTo];
};

export const handleCriteriaTradeTime = (periodVal, criteria) => {
  if (periodVal && periodVal.length > 0) {
    const [from, to] = convertTradeTime(periodVal[0], periodVal[1]);
    criteria.value.from = from;
    criteria.value.to = to;
  } else {
    criteria.value.from = null;
    criteria.value.to = null;
  }
};
